// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs";
//import Turbolinks from "turbolinks";
import * as ActiveStorage from "@rails/activestorage";
import "channels";
import "stylesheets/app_landing/main.scss";
import "stylesheets/app_landing/dashboard.scss";
import "stylesheets/app_landing/legal.scss";
import Vue from "vue/dist/vue.esm";
import axios from "axios";
//import { view_item } from "../js/ga_helper.js";
////console.log(view_item());
require.context("../images", true);
//console.log("start js / react");
window._vue = Vue;
window._axios = axios;
Rails.start();
//Turbolinks.start();
ActiveStorage.start();
